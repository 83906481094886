/* Chinese Fonts */
/* https://chinesefonts.org/ */
/* https://blog.greenroots.info/3-quick-ways-to-add-fonts-to-your-react-app */

/*
Steps to install fonts
(1) Create a folder called fonts under src.
(2) Download the required fonts into the src\fonts folder. 
(3) In the index.css file add:

@font-face {
    font-family: "GoldmanBold";
    src: local("GoldmanBold"),
    url("./fonts/Goldman/Goldman-Bold.ttf") format("truetype");
    font-weight: bold;
}

(4) Now add a class name in the App.css file that uses this family name.

.font-face-gm {
 font-family: "GoldmanBold";
}

(5) Use this class name in your React component:

const FontFace = () => {
  return(
      <div className="card">
          <div className="font-face-gm">
              This is using Font Face. 
              We are linking the <u><b>Goldman</b></u> font from the Google Fonts.
          </div>
      </div>
  )
}
export default FontFace;

*/

@font-face {
    font-family: "HuaWenKaiTi";
    src: local("HuaWenKaiTi"),
     url("./fonts/huawenkaiti.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "aoyagireisyosimo2_regular";
    src: local("aoyagireisyosimo2_regular"),
     url("./fonts/aoyagireisyosimo2_regular.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FZFangSong_Z02Regular";
    src: local("FZFangSong_Z02Regular"),
     url("./fonts/FZFangSong_Z02Regular.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "HanyiSentyGraffiti_Regular";
    src: local("HanyiSentyGraffiti_Regular"),
     url("./fonts/HanyiSentyGraffiti_Regular.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "gongfanmianfeiti_Regular";
    src: local("gongfanmianfeiti_Regular"),
     url("./fonts/gongfanmianfeiti_Regular.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "HanWangKanTan_Regular";
    src: local("HanWangKanTan_Regular"),
     url("./fonts/HanWangKanTan_Regular.ttf") format("truetype");
    font-weight: bold;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.centered_icon {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }
